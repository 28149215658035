import { useState, useMemo } from "react"
import { useTheme, useMediaQuery } from "@material-ui/core"

const useDisplayMethod = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.only("xs"))
  const [displayStyle, setDisplayStyle] = useState(
    // load any saved value
    localStorage.getItem("displayMethod") || "grid"
  )

  const viewStyle = useMemo(() => {
    // always display as list on small devices
    if (matches) return "list"
    else return displayStyle
  }, [matches, displayStyle])

  const changeViewStyle = (value) => {
    if (value !== null) {
      setDisplayStyle(value)
      // store value in browser
      localStorage.setItem("displayMethod", value)
    }
  }

  return { changeViewStyle, viewStyle, isSmallDevice: matches }
}

export default useDisplayMethod
