import React, { useEffect } from "react"
import { Button, CircularProgress, makeStyles } from "@material-ui/core"
import useFetch from "hooks/useFetch"
import { useSnack } from "hooks/useSnack"
import * as issueApi from "api/issue"

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  wrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    position: "absolute",
    flex: 1,
  },
}))

const DownloadButton = ({ children, request, ...props }) => {
  const ref = React.useRef()
  const [submit, isLoading, result, error] = useFetch({ isLoading: false })
  const { push } = useSnack()
  const classes = useStyles()

  const handleClick = async (e) => {
    if (!request) return

    //request download link
    submit(() => issueApi.requestDownload(request))
  }

  useEffect(() => {
    if (error) {
      push({
        message: "An error occurred and File could not be downloaded!",
        severity: "error",
      })
    }
  }, [error])

  useEffect(() => {
    if (result) {
      ref.current.click()
    }
  }, [result])

  return (
    <div className={classes.wrapper}>
      <a
        ref={ref}
        className={classes.hidden}
        href={result}
        target="_blank"
        rel="noopener noreferrer"
        download
      />
      <Button onClick={handleClick} {...props} disabled={isLoading}>
        {isLoading && (
          <CircularProgress
            className={classes.loading}
            size={28}
            thickness={6}
          />
        )}
        {children}
      </Button>
    </div>
  )
}

export default DownloadButton
