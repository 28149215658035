import React, { useState, useContext, useEffect } from "react"
import * as authApi from "api/admin/auth"
import { tokenIsValid } from "libs/admin"

const defaultValues = {
  error: null,
  token: null,
  isSignedIn: false,
  rememberMe: false,
}

const AdminContext = React.createContext(defaultValues)

const Provider = ({ children }) => {
  const [state, setState] = useState({
    ...defaultValues,
  })

  useEffect(() => {
    const token = localStorage.getItem("token")
    const rememberMe = localStorage.getItem("remember-me")

    setState((prev) => ({
      ...prev,
      isSignedIn: token && tokenIsValid(token),
      token,
      rememberMe: rememberMe === "true",
    }))
  }, [])

  const signin = async ({ email, password, remember }) => {
    try {
      const res = await authApi.siginIn(email, password)
      const token = res.data.token

      if (token) {
        if (remember) {
          localStorage.setItem("token", token)
        }
        setState((prev) => ({
          ...prev,
          isSignedIn: true,
          token,
          rememberMe: remember,
        }))

        // keep remember me checked state in storage
        localStorage.setItem("remember-me", remember)
      }
    } catch (error) {
      setState((prev) => ({ ...prev, error: error.message }))
    }
  }

  const signout = () => {
    localStorage.removeItem("token")
    setState((prev) => ({
      ...prev,
      isSignedIn: false,
      token: null,
      error: null,
    }))
  }

  return (
    <AdminContext.Provider value={{ ...state, signin, signout }}>
      {children}
    </AdminContext.Provider>
  )
}

const useAdmin = () => useContext(AdminContext)

export default Provider
export { Provider, useAdmin }
