import React, { useEffect } from "react"
import BookShop from "components/BookShop"
import * as bookshopApi from "api/bookshop"
import useFetch from "hooks/useFetch"

const BookShopComponent = () => {
  const [submit, isLoading, data, error] = useFetch()

  useEffect(() => {
    submit(bookshopApi.getRandom)
  }, [])

  return <BookShop isLoading={isLoading} data={data?.docs} />
}

export default BookShopComponent
