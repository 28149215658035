import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  icon: {
    fontSize: 80,
  },
  text: {
    maxWidth: 120,
    textAlign: "center",
    fontSize: "1.1em",
  },
}))

const DeafaultImage = ({ className, style }) => {
  const classes = useStyles()

  return (
    <div className={[classes.root, className].join(" ")} style={style}>
      <ImageOutlinedIcon className={classes.icon} />
      <Typography className={classes.text}>Image Coming Soon</Typography>
    </div>
  )
}

export default DeafaultImage
