import React from "react"
import { makeStyles, Typography, IconButton } from "@material-ui/core"
import { YouTube, Twitter, LinkedIn, Facebook } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const Social = (props) => {
  const classes = useStyles()

  return (
    <div className={[classes.root, props.className].filter(Boolean).join(" ")}>
      <Typography>Follow ASE on Social Media</Typography>
      <IconButton href="https://twitter.com/theASE" target="_blank">
        <Twitter style={{ color: "#2CAAE1", fontSize: 28 }} />
      </IconButton>
      <IconButton
        href="https://www.linkedin.com/company/the-association-for-science-education/"
        target="_blank"
      >
        <LinkedIn style={{ color: "#007BB6", fontSize: 28 }} />
      </IconButton>
      <IconButton
        href="https://www.youtube.com/user/ASEScienceEducation"
        target="_blank"
      >
        <YouTube style={{ color: "#C51614", fontSize: 28 }} />
      </IconButton>
      <IconButton
        href="https://www.facebook.com/AssociationforScienceEducation"
        target="_blank"
      >
        <Facebook style={{ color: "#3B579D", fontSize: 28 }} />
      </IconButton>
    </div>
  )
}

export default Social
