import React from "react"
import MainRouter from "./routers/MainRouter"
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { Provider as AdminProvider } from "hooks/Admin"
import { SnackProvider } from "hooks/useSnack"
import { Provider as LibraryProvider } from "hooks/Library"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#007582",
      dark: "#013383",
      main: "#021d49",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f4eddf",
      dark: "rgba(0,0,0,0.5)",
    },
  },
  typography: {
    h5: {
      fontSize: 26,
    },
    h6: {
      fontSize: 18,
    },
    body2: {
      fontSize: 12,
      lineHeight: 1.6,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
})

function App() {
  return (
    <AdminProvider>
      <LibraryProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackProvider>
            <MainRouter />
          </SnackProvider>
        </ThemeProvider>
      </LibraryProvider>
    </AdminProvider>
  )
}

export default App
