import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import MainWrapper from "components/MainWrapper"
import Main from "views/Main"
import JournalRouter from "./JournalRouter"
import ErrorBlock from "components/ErrorMessageBlock"

const AdminRouter = React.lazy(() => import("./AdminRouter")) // lazy load as most users wont see this

// TODO: create default page loading componet for suspense
const MainRouter = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            path={"/404"}
            render={() => <ErrorBlock message="page not found" />}
            exact
          />

          <Route path="/admin">
            <AdminRouter />
          </Route>

          <Route>
            <MainWrapper>
              <Switch>
                <Route path="/" exact>
                  <Main />
                </Route>
                <Route path="/:journalName">
                  <JournalRouter />
                </Route>
              </Switch>
            </MainWrapper>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default MainRouter
