import React from "react"
import { Breadcrumbs as Bread, Typography, Link } from "@material-ui/core"
import useBreadcrumbs from "use-react-router-breadcrumbs"
import { Link as RouterLink } from "react-router-dom"

const LinkRouter = (props) => (
  <Link variant="h6" {...props} component={RouterLink} />
)

const Breadcrumbs = () => {
  const crumbs = useBreadcrumbs([
    { path: "/", breadcrumb: "ASE Journal Hub" },
    {
      path: "/:journalName/:issueNumber",
      breadcrumb: ({ match }) => `Issue ${match.params.issueNumber}`,
    },
  ])

  return (
    <Bread maxItems={4} aria-label="breadcrumb" style={{ display: "flex" }}>
      {crumbs.map(({ match, breadcrumb }, index) =>
        index === crumbs.length - 1 ? (
          <Typography variant="h6" key={match.url}>
            {breadcrumb}
          </Typography>
        ) : (
          <LinkRouter key={match.url} to={match.url}>
            {breadcrumb}
          </LinkRouter>
        )
      )}
    </Bread>
  )
}

export default Breadcrumbs
