import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LinkRouter from "./LinkRouter"
import { useLibrary } from "hooks/Library"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    height: 126,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.only("xs")]: {
      height: 75,
      alignItems: "center",
    },
  },
  logo: {
    maxWidth: 166,
    height: "auto",
    overflow: "hidden",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 98,
      height: 44 
    },
  },
  title: {
    color: "white",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
}))

const Header = (props) => {
  const classes = useStyles()
  const library = useLibrary()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <LinkRouter to="/" className={classes.logo}>
          <img
            src="/logo.png"
            style={{ objectFit: "contain", width: "100%" }}
            alt="Association for Science Education logo"
          />
        </LinkRouter>
        <div className={classes.title}>
          <Typography variant="h5" component="h1">
            Welcome {library.name}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Header
