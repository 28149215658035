import { useState, useCallback, useEffect, useRef } from "react"
import { useAdmin } from "hooks/Admin"

const defaults = {
  isLoading: true,
  data: undefined,
  error: undefined,
  hasBeenSubmited: false,
}

const useFetch = (initalStata) => {
  const [state, setState] = useState({ ...defaults, ...initalStata })
  const admin = useAdmin()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => cancel()
  }, [])

  const submit = useCallback((apiRequestFunction) => {
    setState((prev) => ({
      ...defaults,
      ...initalStata,
      isLoading: true,
      hasBeenSubmited: true,
    }))

    apiRequestFunction()
      .then((res) => {
        if (isMounted.current) {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            data: res.data,
          }))
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            error: err ?? { message: "An unknow error occurred." },
          }))

          if (err?.status === 401) {
            admin.signout()
          }
        }
      })
  }, [])

  const cancel = () => {
    isMounted.current = false
  }

  return [
    submit,
    state.isLoading,
    state.data,
    state.error,
    state.hasBeenSubmited,
    cancel,
  ]
}

export default useFetch
