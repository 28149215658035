import axios from "axios"

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL,
})

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    return Promise.reject(error.response.data)
  }
)

const { get, post, put, delete: destroy } = apiClient
export default apiClient
export { get, post, put, destroy }
