import React from "react"
import { makeStyles, Typography, Divider } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 58,
  },
  heading: {
    color: "white",
    fontWeight: "bold",
  },
  subHeading: {
    color: "white",
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    marginBottom: 5,
  },
}))

const SubHeader = ({ title, subtitle, ...props }) => {
  const classes = useStyles()

  return (
    <div className={[classes.root, props.className].join(" ")}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <Typography variant="h5" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.subHeading}>
          {subtitle}
        </Typography>
      </div>
      <Divider style={{ backgroundColor: "white" }} />
    </div>
  )
}

export default SubHeader
