import React from "react"
import {
  makeStyles,
  Typography,
  Box,
  Link,
  ButtonBase,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { useRouteMatch, useHistory } from "react-router-dom"
import DefaultImage from "components/DefaultImage"

const width = 180
const height = 320

const useStyles = makeStyles((theme) => ({
  button: {
    width: (props) => (props.listOrientation ? "100%" : width),
    height: (props) => (props.listOrientation ? "100%" : height),
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    marginBottom: theme.spacing(3),
  },
  issue: {
    display: "flex",
    flexDirection: (props) => (props.listOrientation ? "row" : "column"),
    alignItems: "stretch",
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  image: {
    width: (props) => (props.listOrientation ? 92 : width),
    height: (props) => (props.listOrientation ? 130 : height),
  },
  details: {
    flex: 1,
    padding: theme.spacing(1),
    paddingLeft: (props) => props.listOrientation && theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    minHeight: 62,
  },
  subtitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: theme.typography.caption.fontSize,
  },
}))

const IssueCard = ({
  image,
  issueNo,
  subtitle,
  listOrientation = "grid",
  isloading = false,
  journalName,
}) => {
  const classes = useStyles({
    listOrientation: listOrientation === "grid" ? false : true,
  })

  const { url } = useRouteMatch()
  const history = useHistory()

  if (isloading) {
    return (
      <div className={classes.button}>
        <div className={classes.issue}>
          <Skeleton className={classes.image} variant="rect" />
          <div className={classes.details} style={{ backgroundColor: "white" }}>
            <Skeleton width="40%" height={30} variant="text" />
            <Skeleton width="60%" variant="text" />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <ButtonBase
        className={classes.button}
        onClick={() => history.push(`${url}/${issueNo}`)}
      >
        <Box boxShadow={1} className={classes.issue}>
          {image ? (
            <img
              src={image}
              className={classes.image}
              alt={`${journalName} Issue: ${issueNo} cover`}
              title={`${journalName} Issue: ${issueNo}`}
            />
          ) : (
            <DefaultImage className={classes.image} style={{ color: "#ccc" }} />
          )}

          <div className={classes.details}>
            <Link variant="subtitle1">Issue {issueNo}</Link>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
        </Box>
      </ButtonBase>
    )
  }
}

export default IssueCard
