import React, { useContext, useEffect } from "react"
import { getActive } from "api/library"
import useFetch from "hooks/useFetch"

const defaultValues = {
  isLoading: true,
  name: "",
  logo: "",
  subscriptions: [],
  hasAccess: () => {},
}

const LibraryContext = React.createContext(defaultValues)

const Provider = ({ children }) => {
  const [submit, isLoading, data, error] = useFetch()

  useEffect(() => {
    submit(getActive)
  }, [])

  const hasAccess = (journalId) => {
    const match = data.subscriptions.find((sub) => sub.journalId === journalId)
    return match && (match.isFree || match.status === "active")
  }

  return (
    <LibraryContext.Provider
      value={{
        isLoading,
        ...data,
        error,
        hasAccess,
      }}
    >
      {children}
    </LibraryContext.Provider>
  )
}

const useLibrary = () => useContext(LibraryContext)

export default Provider
export { Provider, useLibrary }
