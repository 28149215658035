import React from "react"
import { makeStyles, Typography, ButtonBase } from "@material-ui/core"
import trainee from "../static/images/trainee.jpg"
import LinkRouter from "./LinkRouter"

const content = {
  title: "Training to be a teacher?",
  text:
    "ASE offers CPD events & workshops to support you through you're early career.",
  link: "https://www.ase.org.uk/trainee-early-career-membership",
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 170,
    display: "flex",
    backgroundImage: `url(${trainee})`,
    backgroundPosition: "center",
    backgroundPositionX: "right",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    textAlign: "left",
    justifyContent: "unset",
  },
  image: {
    width: "100%",
    height: 170,
    objectFit: "cover",
  },
  content: {
    width: "50%",
    height: 170,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    alignSelf: "flex-end",
    color: "white",
  },
}))

const Training = (props) => {
  const classes = useStyles()

  return (
    <ButtonBase
      className={classes.root}
      onClick={() => {
        window.open(content.link, "_blank")
      }}
    >
      <div className={classes.content}>
        <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
          {content.title}
        </Typography>
        <Typography variant="body2" style={{ color: "white" }}>
          {content.text}
        </Typography>
        <LinkRouter variant="button" to="/" className={classes.link}>
          LEARN MORE
        </LinkRouter>
      </div>
    </ButtonBase>
  )
}

export default Training
