import React, { useState, useEffect } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const SnackContext = React.createContext({ push: () => {} })

export const useSnack = () => React.useContext(SnackContext)

export const SnackProvider = (props) => {
  const [snack, setSnack] = useState(null)
  const [open, setOpen] = useState(false)

  const push = ({ message = "", severity = "success" }) => {
    setSnack({ message, severity })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <SnackContext.Provider value={{ push }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        onExited={() => setSnack(null)}
      >
        <Alert
          onClose={handleClose}
          severity={snack?.severity}
          variant="filled"
        >
          {snack?.message}
        </Alert>
      </Snackbar>

      {props.children}
    </SnackContext.Provider>
  )
}
