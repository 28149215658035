import React from "react"
import { withStyles } from "@material-ui/core"
import { Menu, Apps } from "@material-ui/icons"
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab"

const GridListButtons = ({ value, onChange }) => {
  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={(_event, value) => onChange(value)}
      aria-label="list-or-grid-view"
      size="small"
    >
      <ToggleButton value={"grid"} aria-label="grid-view">
        <Apps style={{ fontSize: 26 }} />
      </ToggleButton>
      <ToggleButton value={"list"} aria-label="list-view">
        <Menu style={{ fontSize: 26 }} />
      </ToggleButton>
    </StyledToggleButtonGroup>
  )
}

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: "none",
    margin: theme.spacing(0.5),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export default GridListButtons
