import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import JournalCard from "components/JournalCard"
import SubHeader from "components/SubHeader"
import { useLibrary } from "hooks/Library"
import * as journalApi from "api/journals"
import useFetch from "hooks/useFetch"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    gridTemplateAreas: `"SSR PS"
    "SSRP SSRD"
     "EiS STE"
    "JES ASEI"
   `,
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "SSR"
      "SSRP"
      "SSRD"
      "PS"
      "EiS"
      "STE"
      "JES"
      "ASEI"
      `,
      margin: theme.spacing(2, 1),
    },
  },
}))

const Main = (props) => {
  const classes = useStyles()
  const { hasAccess } = useLibrary()
  const [submit, isLoading, data, error] = useFetch()

  useEffect(() => {
    // get list of journals
    submit(() => journalApi.getAll())
  }, [])

  if (error) {
    return error.message
  } else if (isLoading) {
    return "loading"
  } else if (data) {
    return (
      <>
        <SubHeader title="Journals" />
        <div className={classes.root}>
          {data.docs.map((journal) => {
            hasAccess()
            const isDisabled = () => {
              if (journal.isFree) {
                return false
              }

              return !hasAccess(journal._id)
            }

            return (
              <JournalCard
                key={journal.name}
                title={journal.title}
                shortTitle={journal.name}
                description={journal.shortDescription}
                image={journal.coverImage}
                to={`/${journal.name}`}
                disabled={isDisabled()}
              />
            )
          })}
        </div>
      </>
    )
  } else {
    return null
  }
}

export default Main
