import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  grid: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1),
    },
    flexDirection: (props) => props.flexDirection,
  },
}))

const GridWrapper = (props) => {
  const classes = useStyles({
    flexDirection: props.viewStyle === "list" ? "column" : "row",
  })

  return (
    <div className={[classes.grid, props.className].join(" ")}>
      {props.children}
    </div>
  )
}

export default GridWrapper
