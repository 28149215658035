import React from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import Journal from "views/Journal"
import Issue from "views/Issue"

const JournalRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <Journal />
      </Route>
      <Route path={`${path}/:issueNumber`}>
        <Issue />
      </Route>
    </Switch>
  )
}

export default JournalRouter
