import React, { useEffect } from "react"
import {
  makeStyles,
  Divider,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import IssueContents from "components/IssueContents"
import Subheader from "components/SubHeader"
import { useParams, Redirect } from "react-router-dom"
import { GetApp } from "@material-ui/icons"
import * as issueApi from "api/issue"
import useFetch from "hooks/useFetch"
import moment from "moment"
import DownloadButton from "components/DownloadButton"
import DefaultImage from "components/DefaultImage"
import { useLibrary } from "hooks/Library"
import NoAccessToResource from "components/NoAccessToResource"

const useStyles = makeStyles((theme) => ({
  summery: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  image: {
    width: 180,
    height: 180 * 1.5,
    [theme.breakpoints.only("xs")]: {
      width: 98,
      height: 98 * 1.5,
      float: "left",
      marginRight: theme.spacing(2),
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  details: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },
  subheader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    "& > *": {
      fontStyle: "italic",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
    },
  },
  downloadBtn: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  pdfBtn: {
    color: theme.palette.secondary.dark,
  },
}))

const Issue = (props) => {
  const classes = useStyles()
  const { journalName, issueNumber } = useParams()
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.only("xs"))
  const [submit, isLoading, data, error] = useFetch()
  const { hasAccess } = useLibrary()

  useEffect(() => {
    if (journalName && issueNumber) {
      submit(() => issueApi.getIssue(journalName, issueNumber))
    }
  }, [])

  const sorted = React.useMemo(() => {
    if (data?.articles.length > 0) {
      return data.articles.sort((a, b) => a.page - b.page)
    } else {
      return []
    }
  }, [data])

  if (!(journalName && issueNumber)) {
    return <Redirect to="/404" />
  }

  const SortedArticles = ({ articles }) => {
    if (articles.length > 0) {
      return <IssueContents articles={articles} />
    } else {
      return null
    }
  }

  const Image = () => {
    if (data.coverImage) {
      return (
        <img
          className={classes.image}
          src={data.coverImage}
          alt={`issue-${data?.issueNo}`}
        />
      )
    } else {
      return (
        <DefaultImage style={{ color: "#ccc" }} className={classes.image} />
      )
    }
  }

  if (error) {
    return <Redirect to="/404" />
  } else if (isLoading) {
    return "loading"
  } else if (data) {
    const issue = data

    if (issue.journal.isFree || hasAccess(issue?.journalId)) {
      return (
        <>
          <Subheader
            title={issue?.journal?.name}
            subtitle={issue?.journal?.theme}
          />
          <div className={classes.summery}>
            {!match && (
              <Box boxShadow={1}>
                <Image />
              </Box>
            )}
            <div className={classes.details}>
              <Typography variant="h6">{`Issue ${issue?.issueNo}: ${
                issue.theme ?? ""
              }`}</Typography>
              <Divider className={classes.divider} />
              <div className={classes.subheader}>
                <Typography>
                  Editor: {issue?.editors.join(", ") ?? "Unknown"}
                </Typography>
                <Typography>
                  Published:{" "}
                  {issue?.published
                    ? moment(issue.published).format("MMMM YYYY")
                    : "Unknown"}
                </Typography>
              </div>
              {match && <Image />}
              <Typography variant="body2" style={{ textAlign: "justify" }}>
                {issue?.description}
              </Typography>
            </div>
          </div>
          {issue.PDFFilename && (
            <div className={classes.buttons}>
              <DownloadButton
                request={{ issue: issue._id }}
                className={classes.downloadBtn}
                startIcon={<GetApp />}
                variant="contained"
              >
                Download Full Issue PDF
              </DownloadButton>
            </div>
          )}

          <SortedArticles articles={sorted} />
        </>
      )
    } else {
      return <NoAccessToResource />
    }
  }
}

export default Issue
