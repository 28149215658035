import React from "react"
import { useLibrary } from "hooks/Library"
import CircularProgress from "@material-ui/core/CircularProgress"
import Block from "components/ErrorMessageBlock"

import {
  Container,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"

import Header from "./Header"
import Banner from "./Banner"
import Social from "./Social"
import Footer from "./Footer"
import Breadcrumbs from "./Breadcrumbs"
import Training from "./Training"
import Bookshop from "containers/BookShopComponent"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: "100vh",
    padding: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    height: theme.spacing(8),
  },
  main: {
    flexGrow: 1,
  },
  leftCol: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },
  rightCol: {
    paddingLeft: theme.spacing(2),
    "& > *": {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      margin: theme.spacing(0, 1),
    },
  },
  onMobile: {
    margin: theme.spacing(0, 1),
    textAlign: "center",
  },
}))

const MainWrapper = ({ breadcrumbs, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.only("xs"))

  const library = useLibrary()

  if (library.error) {
    return "You do not have access to this resource"
  } else if (library.isLoading) {
    return (
      <Block>
        <CircularProgress size={38} />
      </Block>
    )
  } else if (library.name) {
    return (
      <Container maxWidth="md" className={classes.root}>
        <Header />
        <Banner />
        <div className={classes.bar}>
          <Breadcrumbs />
          {!matches && <Social />}
        </div>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm={8} className={classes.leftCol}>
            {props.children}
          </Grid>

          <Grid item xs={12} sm={4} className={classes.rightCol}>
            <Training />
            <Bookshop />
          </Grid>
        </Grid>

        <Footer before={matches && <Social className={classes.onMobile} />} />
      </Container>
    )
  }
}

export default MainWrapper
