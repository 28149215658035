import React from "react"
import ErrorBlock from "components/ErrorMessageBlock"

const NoAccessToResource = () => {
  return (
    <ErrorBlock message="You do not have a active subscription for this resource." />
  )
}

export default NoAccessToResource
