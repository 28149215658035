import { get } from "api/base"

export const getAll = (journalName, params) => {
  return get(`/journal/${journalName}/issue`, { params })
}

export const getIssue = (journalName, issueNo) => {
  return get(`/journal/${journalName}/issue/${issueNo}`)
}

export const getIssueArticles = (journalName, issueNo) => {
  return get(`/journal/${journalName}/issue/${issueNo}/articles`)
}

export const requestDownload = (request) => {
  return get(`/journal/download`, { params: request })
}
