import jwtDecode from "jwt-decode"

export const tokenIsValid = (token) => {
  if (token) {
    const decoded = jwtDecode(token)
    return decoded.exp * 1000 >= Date.now()
  } else {
    throw new Error("No token provided.")
  }
}
