import React, { useState } from "react"
import {
  makeStyles,
  Card,
  CardMedia,
  CardActionArea,
  Divider,
  Typography,
  Link,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import DefaultImage from "components/DefaultImage"

import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    gridArea: (props) => props.shortTitle,
    display: "flex",
    height: 170,
    borderRadius: 0,
    backgroundColor: (props) =>
      props.disabled ? "#ccc" : theme.palette.primary.light,
  },
  cover: {
    width: 120,
    height: 170,
  },
  content: {
    color: "white",
    flex: 1,
    display: "flex",
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: "column",
  },
  short: {
    fontWeight: "bold",
  },
  title: {
    marginLeft: theme.spacing(1),
    flexWrap: "wrap",
    lineHeight: 1,
    marginBottom: 5,
  },
  description: {
    flexGrow: 1,
  },
  divider: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  link: {
    alignSelf: "flex-end",
    color: "white",
  },
  actionArea: {
    display: "flex",
    alignItems: "stretch",
  },
  imgDisable: {
    opacity: 0.4,
    filter: "alpha(opacity=40)",
    backgroundColor: "#000",
  },
  disabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: 0.6,
    zIndex: 1,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    cursor: "pointer",
    userSelect: "none",
  },
}))

const JournalCard = ({
  title,
  shortTitle,
  description,
  to,
  image,
  isLoading = false,
  disabled = false,
}) => {
  const classes = useStyles({ disabled, shortTitle })
  const [showDisableMessage, setShowDisableMessage] = useState(false)
  const history = useHistory()

  if (isLoading) {
    return <Skeleton variant="rect" className={classes.root} />
  } else {
    return (
      <Card
        className={classes.root}
        elevation={disabled ? 0 : 1}
        onMouseEnter={() => setShowDisableMessage(disabled && true)}
        onMouseLeave={() => setShowDisableMessage(false)}
      >
        {showDisableMessage && (
          <div className={classes.disabled} onClick={() =>  window.open("https://www.ase.org.uk/libraries", "_blank")}>
            <Typography variant="h6">
            You do not currently have access to this journal. To find out more <u>click here</u>
            </Typography>
          </div>
        )}

        <CardActionArea
          className={classes.actionArea}
          onClick={() => !disabled && to && history.push(to)}
          disabled={disabled}
        >
          {image ? (
            <CardMedia
              className={[classes.cover, disabled && classes.imgDisable].join(
                " "
              )}
              title={title}
              image={image}
            />
          ) : (
            <DefaultImage className={classes.cover} />
          )}

          <div className={classes.content}>
            <div className={classes.header}>
              <Typography variant="h5" className={classes.short}>
                {shortTitle}
              </Typography>
              <Typography variant="body2" className={classes.title}>
                {title}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
            {!disabled && (
              <Link variant="button" className={classes.link}>
                VIEW ISSUES
              </Link>
            )}
          </div>
        </CardActionArea>
      </Card>
    )
  }
}

export default JournalCard
