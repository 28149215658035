import React from "react"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import LinkRouter from "components/LinkRouter"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const ErrorMessageBlock = ({ message, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {children ? (
        children
      ) : (
        <>
          <Typography>{message}</Typography>
          <LinkRouter to="/">Return to main page.</LinkRouter>
        </>
      )}
    </div>
  )
}

export default ErrorMessageBlock
