import React from "react"
import { makeStyles, Select, FormControl, MenuItem } from "@material-ui/core"

import GridListButtons from "./GridListButtons"

const useStyles = makeStyles((theme) => ({
  selectBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
    },
  },
  inputSelects: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}))

const SortBar = ({ value, onChange, onViewChange, viewValue, visable }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    onChange("issueNo", event.target.value)
  }
  return (
    <div className={classes.selectBar}>
      <div className={classes.inputSelects}>
        {onChange && (
          <FormControl className={classes.formControl}>
            Sort By:
            <Select
              labelId="select-order-label"
              name="select-order"
              value={value}
              onChange={handleChange}
            >
              <MenuItem value={1}>Issue No. Asc</MenuItem>
              <MenuItem value={-1}>Issue No. Desc</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>

      {visable && (
        <div className={classes.inputSelects}>
          <GridListButtons value={viewValue} onChange={onViewChange} />
        </div>
      )}
    </div>
  )
}

export default SortBar
