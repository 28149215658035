import React from "react"
import {
  makeStyles,
  Typography,
  ButtonBase,
  Divider,
  Link,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"

const useStyles = makeStyles((theme) => ({
  content: {
    height: 125,
    display: "flex",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "stretch",
    width: "100%",
  },
  bookText: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.secondary,
  },
  bookImage: {
    width: 88,
    height: "100%",
    objectFit: "cover",
  },
  divider: {
    margin: theme.spacing(1),
  },
}))

const BookShop = ({ isLoading, data = [] }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        From the bookshop
      </Typography>
      {isLoading ? (
        <>
          <BookSkeleton />
          <Divider className={classes.divider} />
          <BookSkeleton />
          <Divider className={classes.divider} />
          <BookSkeleton />
        </>
      ) : (
        data.map((book, index) => (
          <React.Fragment key={book.title}>
            <Book {...book} />
            {index < data.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))
      )}
    </div>
  )
}

const Book = ({ coverImage, title, authors, price, link }) => {
  const classes = useStyles()

  return (
    <ButtonBase
      className={classes.content}
      onClick={() => window.open(link, "_blank", "noopener,noreferrer")}
    >
      <img src={coverImage} alt={title} className={classes.bookImage} />
      <div className={classes.bookText}>
        <Link style={{ flex: 1 }}>{title}</Link>
        <Typography style={{ flex: 2, fontStyle: "italic" }}>
          by {authors.join(", ")}
        </Typography>
        <Typography variant="h6">£{price}</Typography>
        <Typography>*ASE Member Price</Typography>
      </div>
    </ButtonBase>
  )
}

const BookSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Skeleton variant="rect" className={classes.bookImage} />
      <div className={classes.bookText}>
        <Skeleton style={{ flex: 1 }} />
        <Skeleton width="60%" />
        <div style={{ flex: 1 }} />
        <Skeleton style={{ flex: 1 }} width="30%" />
      </div>
    </div>
  )
}

export default BookShop
