import React from "react"
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Link,
  AccordionActions,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DownloadButton from "components/DownloadButton"

const useStyles = makeStyles((theme) => ({
  contents: {
    padding: theme.spacing(1),
  },
  heading: {
    flexBasis: "10%",
    flexShrink: 0,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    flexBasis: "90%",
    [theme.breakpoints.only("xs")]: {
      fontWeight: 500,
      fontSize: 12,
      paddingLeft: theme.spacing(1),
    },
  },
  actions: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12) ",
  },
  content: {
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  accordionSummary: {
    padding: theme.spacing(0),
  },
}))

const IssueContents = ({ articles = [] }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.contents}>
      <Typography variant="h5">Articles</Typography>
      <Divider />
      {articles.map((chapter) => (
        <ChapterPanel
          key={chapter.page}
          onChange={handleChange}
          expanded={expanded}
          {...chapter}
        />
      ))}
    </div>
  )
}

const ChapterPanel = ({
  page,
  title,
  description,
  expanded,
  onChange,
  topic,
  ageGroups,
  PDFFilename,
  authors,
  ...chapter
}) => {
  const classes = useStyles()

  return (
    <Accordion
      elevation={0}
      expanded={expanded === page}
      onChange={onChange(page)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`page${page}-content`}
        id={`page${page}-header`}
        classes={{
          content: classes.content,
          root: classes.accordionSummary,
        }}
      >
        <Typography variant="h6" className={classes.heading}>
          P.{page}
        </Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {authors.length > 0 && (
          <Typography variant="subtitle2">
            Authors:{" "}
            {authors
              .map((author) => author.trim())
              .filter(Boolean)
              .join(", ")}
          </Typography>
        )}
        {topic && <Typography variant="subtitle2">Topic: {topic}</Typography>}
        {ageGroups && (
          <Typography variant="subtitle2">
            Age groups: {ageGroups.join(", ")}
          </Typography>
        )}
        <br />
        <Typography variant="body2">{description}</Typography>
      </AccordionDetails>
      <AccordionActions className={classes.actions}>
        {PDFFilename && (
          <DownloadButton
            request={{ article: chapter._id }}
            style={{ cursor: "pointer", marginRight: 24 }}
          >
            Download Article
          </DownloadButton>
        )}
      </AccordionActions>
    </Accordion>
  )
}

export default IssueContents
