import axios from "axios"
import { post, get } from "api/base"

const auth = (token) => {
  const authApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT_URL,
  })

  authApiClient.interceptors.request.use((req) => {
    req.headers.authorization = `Bearer ${token}`
    return req
  })

  authApiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(error.response)
    }
  )

  return authApiClient
}

export const siginIn = async (email, password) => {
  return await post("/admin/login", { email, password })
}

export const getAll = (token) => {
  return auth(token).get("/admin")
}

export const deleteUser = (token, id) => {
  return auth(token).delete(`/admin/${id}`)
}

export const createUser = (token, payload) => {
  return auth(token).post("/admin", payload)
}

export default auth
