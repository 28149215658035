import React from "react"
import { makeStyles, Paper } from "@material-ui/core"
import bannerImage from "../static/images/banner.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: 248,
    display: "flex",
    alignItems: "flex-end",
    backgroundImage: (props) => `url(${props.image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundPositionY: -218,
    [theme.breakpoints.only("xs")]: {
      height: 120,
      backgroundPositionY: "center",
    },
  },
}))

const Banner = (props) => {
  const classes = useStyles({ image: bannerImage })

  return <div className={classes.root} />
}

export default Banner
